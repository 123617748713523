@import "../abstracts/animate";
@import "../mixins/mixins";

.case-item-name span::after,
.case-item-name span:before {
  position: absolute;
  display: block;
  width: 30px;
  height: 31px;
  content: '';
  top: 0;

  // @media(max-width: 768px) {
  //   z-index: -1;
  // }
}

.case-item-name span:before {
  left: -30px;
  background: url(../images/case-cat-name-bg-left.png) no-repeat;
  z-index: -1;

  @media(max-width: 540px) {
    left: -15px;
  }
}

.case-item-name span::after {
  right: -30px;
  top: 3px;
  background: url(../images/case-cat-name-bg-right.png) no-repeat;
  z-index: -1;

  @media(max-width: 540px) {
    right: -15px;
  }
}

.case-item[data-rar="06c50c"] .case-item-name span::after,
.case-item[data-rar="06c50c"] .case-item-name span:before {
  position: absolute;
  display: block;
  width: 30px;
  height: 34px;
  content: '';
  top: 0;
}

.case-item-name {
  span {
    min-height: 31px;
    display: inline-flex;
    align-items: center;
    position: relative;
    line-height: 33px;
    background: url(../images/case-cat-name-bg-center.png) repeat-x;

    // @media(max-width: 359px) {
    //   max-width: calc(100% - 50px);
    // }
    .text-cut {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      width: 100%;
      min-width: 100%;
      line-height: 15px;
      padding-top: 2px;
      height: auto;
      min-height: auto;
    }
  }

  i {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: rgba(213, 185, 255, 0.5);
    margin-left: 10px;
  }
}

.case-row-small {
  .case-item-name {
    span {
      font-size: 12px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      line-height: 14px;
    }

    i {
      margin-left: 2px;
    }
  }

  .case-cost {
    min-width: auto;
    padding: 0 8px;
    margin: 6px 0;

    @media(max-width: 420px) {
      margin-top: 0;
    }
    @media(max-width: 359px) {
      font-size: 16px;
    }

    .rub {
      margin-left: 0;
    }
  }

  .case-cost-battle-pass {
    padding: 0 4px;
    margin: 0 4px;
  }
}

.case-item[data-rar="06c50c"] {
  .case-item-name {
    i {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.case-item[data-rar="06c50c"] .case-item-name span:before {
  left: -30px;
  background: url(../images/case-cat-name-bg-free-left.png) no-repeat;

  @media(max-width: 359px) {
    left: -15px;
  }
}

.case-item[data-rar="06c50c"] .case-item-name span::after {
  right: -30px;
  top: 0;
  background: url(../images/case-cat-name-bg-free-right.png) no-repeat;

  @media(max-width: 359px) {
    right: -15px;
  }
}

.case-item[data-rar="06c50c"] .case-item-name span {
  color: rgba(255, 255, 255, 0.8);
  background: url(../images/case-cat-name-bg-free-center.png) repeat-x;
}

.cases .case-item {
  // width: calc(19.5% - 10px);
  width: calc(16.6% - 10px);
  max-width: 304px;
  vertical-align: top;
  top: 0;
  margin: 0 14px 40px;
  transition: all 0.5s;
  text-decoration: unset;
  z-index: 4;

  @media(max-width: 1366px) {
    width: calc(16.6% - 10px);
  }
  @include large() {
    width: calc(20% - 10px);
  }
  @media (max-width: 992px) {
    width: calc(25% - 28px);
  }
  @media (max-width: 768px) {
    width: calc(33.3% - 28px);
  }
  @media (max-width: 540px) {
    width: calc(50% - 28px);
    margin: 0 10px 65px;
    height: 212px;
  }

  &.bounce {
    animation: bounce 0.3s ease infinite alternate;

    @keyframes bounce {
      100% {
        top: -10px;
      }
    }
  }

  &.light {
    .case-item-img {
      &:before {
        @extend %before-after;
        width: 312px;
        height: 312px;
        top: 50%;
        left: 50%;
        background: url(../images/case-win-light.png) no-repeat 0 0;
        animation: spinCenter 8s linear infinite;
        z-index: -1;
      }
    }
  }

  .drop-chance {
    bottom: auto;
    top: 152px;
    right: 0;
    left: auto;
    background: #3f0047;
    height: 24px;
    min-width: 62px;
    font-size: 16px;
  }
}

.cases .case-row-small .case-item {
  width: calc(14.2% - 20px);
  max-width: initial;
  margin: 0 10px 50px 10px;

  @media(max-width: 1366px) {
    width: calc(16.6% - 20px);
  }
  @media(max-width: 1024px) {
    width: calc(25% - 20px);
  }
  @media(max-width: 768px) {
    width: calc(33.3% - 20px);
  }
  @media(max-width: 540px) {
    width: calc(50% - 20px);
    height: 212px;
    margin-bottom: 40px;
  }

  &-img {
    height: 165px;
    margin-bottom: 10px;

    @media(max-width: 540px) {
      height: 154px;
    }
  }
}

.case-item-wrap {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1840px;
  margin: 0 auto;

  @media(max-width: 560px) {
    padding: 0 6px;
  }
}

.case-item,
.case-item-img,
.case-item-nums,
.footer {
  position: relative;
}

.case-item-nums {
  font-weight: 500;
  color: #ccc;
  font-size: 16px;
  margin-bottom: 5px;
  min-height: 33px;
  line-height: 33px;
}

.case-item-nums .fa {
  margin-right: 11px;
  text-shadow: 0px 0px 1px #ff2c3b, 0 0 8px #ff2f2f;
  color: #fef2c8;
}

.case-item-nums .fa-bolt {
  width: 7px;
  height: 14px;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;

  &:before {
    background: url(../images/lightning.svg) no-repeat 0 0;
    content: '';
    width: 55px;
    font-size: 0;
    height: 62px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
  }
}

.case-item-nums-wrap {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 24px;
}

.case-item-nums .red {
  color: #f5412e;
}

.case-item-img {
  height: 304px;
  position: relative;
  margin-bottom: -70px;

  @media(max-width: 1366px) {
    height: 125px;
    margin-bottom: 0px;
  }

  .lazy-load-image-background {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 360px;
    width: 360px;

    svg {
      transform: scale(1.2);
      top: 8%;
      position: absolute;
      left: 8%;
    }
  }

  img {
    max-width: 304px;
  }
}

.cases-all .case-row-small {
  .case-item-img {
    //height: 165px;
    height: 212px;
    margin-bottom: -20px;

    @media(max-width: 1366px) {
      height: 154px;
      margin-bottom: 0px;
    }
  }
}

.case-item-img .not-hover,
.case-item-img .hover,
.case-item-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 304px;
  min-width: 256px;
}

.case-row-small .case-item-img .not-hover,
.case-row-small .case-item-img .hover,
.case-row-small .case-item-img img {
  max-width: 154px;
  min-width: 154px;
}

.case-row-small .case-item-img svg {
  transform: translate(-50%, 0%) scale(.5);
  display: block;
  position: absolute;
  top: -40px;
  left: 50%;
}

.case-item-name {
  color: rgba(213, 185, 255, 0.5) !important;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;

  @media(max-width: 420px) {
    font-size: 12px;
  }

  .text-overflow {
    max-width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  //  new
  i.case-before-free {
    display: none;
  }
}

.case-item[data-rar='b0c3d9'] .case-item-name,
.case-item[data-rar='b0c3d9'] .case-item-nums .red,
.case-item[data-rar='b0c3d9'] .open-case span {
  color: #b0c3d9;
}

.case-item[data-rar='5e98d9'] .case-cost,
.case-item[data-rar='5e98d9'] .case-item-name,
.case-item[data-rar='5e98d9'] .case-item-nums .red,
.case-item[data-rar='5e98d9'] .open-case span {
  color: #5e98d9;
}

.case-item[data-rar='4b69ff'] .case-cost,
.case-item[data-rar='4b69ff'] .case-item-name,
.case-item[data-rar='4b69ff'] .case-item-nums .red,
.case-item[data-rar='4b69ff'] .open-case span {
  color: #4b69ff;
}

.case-item[data-rar='8847ff'] .case-cost,
.case-item[data-rar='8847ff'] .case-item-name,
.case-item[data-rar='8847ff'] .case-item-nums .red,
.case-item[data-rar='8847ff'] .open-case span {
  color: #8847ff;
}

.case-item[data-rar='d32ce6'] .case-cost,
.case-item[data-rar='d32ce6'] .case-item-name,
.case-item[data-rar='d32ce6'] .case-item-nums .red,
.case-item[data-rar='d32ce6'] .open-case span {
  color: #bd28cf;
}

.case-item[data-rar='eb4b4b'] .case-cost,
.case-item[data-rar='eb4b4b'] .case-item-name,
.case-item[data-rar='eb4b4b'] .case-item-nums .red,
.case-item[data-rar='eb4b4b'] .open-case span {
  color: #eb4b4b;
}

.case-item[data-rar='dea937'] .case-cost,
.case-item[data-rar='dea937'] .case-item-name,
.case-item[data-rar='dea937'] .case-item-nums .red,
.case-item[data-rar='dea937'] .open-case span {
  color: #dea937;
}

.case-item[data-rar='e4ae39'] .case-cost,
.case-item[data-rar='e4ae39'] .case-item-name,
.case-item[data-rar='e4ae39'] .case-item-nums .red,
.case-item[data-rar='e4ae39'] .open-case span {
  color: #e4ae39;
}

.case-item[data-rar='56d30d'] .case-item-name,
.case-item[data-rar='56d30d'] .case-item-nums .red,
.case-item[data-rar='56d30d'] .open-case span {
  color: #56d30d;
}

.case-item-tooltipes {
  position: absolute;
  bottom: 145px;
  left: -20px;
  z-index: 2;

  @media(max-width: 1366px) {
    bottom: auto;
    top: 105px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.case-row-small {
  padding-bottom: 60px;

  .case-item-tooltipes {
    bottom: 61px;
  }

  .case-hit,
  .case-latest,
  .case-free {
    width: 67px;
    height: 43px;
  }
}

.case-hit,
.case-latest,
.case-free {
  height: 53px;
  width: 99px;
  z-index: 3;
  margin-top: -20px;
  margin-bottom: 2px;

  @media(max-width: 1366px) {
    width: 67px;
    height: 43px;
  }
}

.case-hit {
  background: url(../images/hit-img.svg) no-repeat 0 0;
  background-size: contain;
}

.en .case-hit,
.de .case-hit {
  background: url(../images/hit-img.svg) no-repeat 0 0;
  background-size: contain;
}

.case-latest {
  background: url(../images/latest-img.svg) no-repeat 0 0;
  background-size: contain;
}

.case-free {
  background: url(../images/img-free.svg) no-repeat 0 0;
  background-size: contain;
}

.en .case-latest,
.de .case-latest {
  background: url(../images/latest-img.svg) no-repeat 0 0;
  background-size: contain;
}

.case-item-img .not-hover,
.case-item-img .hover,
.case-item-img img,
.top-drop-item > img {
  transform: translate(-50%, -50%);
}


.add-money,
.authorization-social a,
.case-item-nums,
.case-play,
.case-play .case-item,
.case-play-name,
.case-row,
.footer-bot,
.in-case-item,
.in-case-title,
.stats,
.top-drop-item-hover {
  text-align: center;
  user-select: none;
}

.cases {
  .case-item {
    height: 325px;
    margin-bottom: 16px;

    @media(max-width: 1366px) {
      height: auto;
      margin-bottom: 66px;
    }
    @media(max-width: 540px) {
      margin-bottom: 40px;
    }

    a {
      text-decoration: none;
    }

    &.disable {
      opacity: 0.49;
      pointer-events: none;
    }

    .timer {
      display: flex;
      align-items: center;
      padding: 0px 8px 0px 4px;
      height: 24px;
      background: linear-gradient(270deg, #3C0124 -25%, #340855 125%);
      border: 2px solid rgba(63, 9, 101, 0.42);
      border-radius: 12px;
      position: absolute;
      //top: 29px;
      top: -25px;
      right: 0;
      font-size: 14px;
      z-index: 2;
      color: rgba(230, 155, 255, 0.4);

      @media(max-width: 1366px) {
        top: -25px;
        right: 50%;
        transform: translateX(50%);
        white-space: nowrap;
      }

      .day {
        margin-right: 4px;
        text-shadow: -1px -1px 0 rgba(235, 75, 75, .48), 1px -1px 0 rgba(235, 75, 75, .48), -1px 1px 0 rgba(235, 75, 75, .48), 1px 1px 0 rgba(235, 75, 75, .48), 0 0 24px rgba(235, 75, 75, .48), 0 0 13px rgba(235, 75, 75, .48);
        color: #fef2c8;
      }

      .icon {
        color: #FF2C3B;
        box-shadow: 0 0 12px 1px #ff2c3b;
        background: #FEF2C8;
        border-radius: 50%;
        font-size: 17px;
        margin: 0 6px 0 -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        font-size: 20px;
      }
    }
  }

  .case-row-small {
    .case-item {
      height: 265px;

      @media(max-width: 540px) {
        height: 212px;
      }
    }

    .timer {
      top: 29px;
    }
  }
}

//новый
.cases .case-row-small .case-item a {
  max-width: 212px;
  height: 100%;
  display: block;
  margin: 0 auto;
  position: relative;

  .case-item-tooltipes {
    bottom: 61px;
  }
}

.cases .case-item a .case-item-img .img-default,
.cases .case-item a .case-item-img .hover,
.cases .case-item a .case-item-img .not-hover {
  opacity: 1;
  top: 0;
  left: 50%;
  width: auto;
  height: auto;
  position: absolute;
  transform: translate(-50%, 0%);
  max-width: 304px;

  @media(max-width: 1440px) {
    max-width: 100%;
  }
  @media(max-width: 1366px) {
    min-width: auto;
    width: 100%;
    height: auto;

    svg {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translate(-50%, 0%) scale(.7);
    }
  }
}

.cases .case-row-small .case-item a .case-item-img .img-default,
.cases .case-row-small .case-item a .case-item-img .hover,
.cases .case-row-small .case-item a .case-item-img .not-hover {
  max-width: 212px;

  @media(max-width: 1366px) {
    svg {
      transform: translate(-50%, 0%) scale(.5);
    }
  }
  @media(max-width: 480px) {
    max-width: 154px;
  }
}

.cases .case-row-small .case-item .case-item-nums {
  top: -15px;
  right: 50%;
  transform: translateX(50%);
}

.cases .case-item a .case-item-img .hover {
  opacity: 0;
  transform: translate(-50%, 0%) scale(1.053);
  margin-top: -4px;
}

.cases .case-row-small .case-item a .case-item-img .hover {
  transform: translate(-50%, 0%) scale(1.076);
  margin-top: -2px;
}

.cases {
  .case-item {
    &:not(.disable) {
      &:hover {
        cursor: pointer;

        .case-item-name {
          color: rgba(230, 155, 255, 0.64) !important;
        }

        .case-item-img {
          .not-hover {
            //comment to avoid image dissapearing while hover specific part of case item
            //opacity: 0;
          }

          .hover {
            opacity: 1;
          }
        }
      }

      &.case-item-static {
        &:not(.disable) {
          &:hover {
            .not-hover {
              opacity: 1;
            }

            .hover {
              opacity: 0;
            }
          }
        }
      }

      &.case-item-hover {
        &:not(.disable) {
          .not-hover {
            opacity: 0;
          }

          .hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.btn,
.open-case {
  min-width: 202px;
  line-height: 35px;
  border-radius: 25px;
  border: 2px solid #fff;
}

.btn-open-case {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 24px;
  color: #fff;
  border: 1px solid rgba(211, 44, 230, 0);
  text-shadow: 0px 0px 6px #f7c0fe;
  text-transform: uppercase;
  height: 48px;
  border-radius: 24px;
  padding: 0 16px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  letter-spacing: 1px;
  z-index: 2;
  opacity: 0;
  transition: all .5s;
  transition-delay: 1s;
}

.case-item:hover:not(.disable) .btn-open-case {
  opacity: 1;
}

.case-item[data-rar='b0c3d9'] .btn-open-case {
  background: rgba(176, 195, 217, 0.4);
  border: 4px solid #b7cde5;
  box-shadow: 0px 0px 16px #b0c3d9, 0px 0px 32px #b0c3d9,
  inset 0px 0px 64px #b0c3d9;
}

.cases .case-item[data-rar='b0c3d9']:not(.disable):hover .case-cost {
  background: rgba(176, 195, 217, 0.08);
}

.case-item[data-rar='5e98d9'] .btn-open-case {
  background: rgba(94, 152, 217, 0.4);
  border: 4px solid #64a1e5;
  box-shadow: 0px 0px 16px #5e98d9, 0px 0px 32px #5e98d9,
  inset 0px 0px 64px #5e98d9;
}

.cases .case-item[data-rar='5e98d9']:not(.disable):hover .case-cost {
  background: rgba(94, 152, 217, 0.08);
}

.case-item[data-rar='4b69ff'] .btn-open-case {
  background: rgba(75, 105, 255, 0.4);
  border: 4px solid #5671f8;
  box-shadow: 0px 0px 16px #4b69ff, 0px 0px 32px #4b69ff,
  inset 0px 0px 64px #4b69ff;
}

.cases .case-item[data-rar='4b69ff']:not(.disable):hover .case-cost {
  background: rgba(75, 105, 255, 0.08);
}

.case-item[data-rar='8847ff'] .btn-open-case {
  background: rgba(136, 71, 255, 0.4);
  border: 4px solid #9259fc;
  box-shadow: 0px 0px 16px #8847ff, 0px 0px 32px #8847ff,
  inset 0px 0px 64px #8847ff;
}

.cases .case-item[data-rar='8847ff']:not(.disable):hover .case-cost {
  background: rgba(211, 44, 230, 0.08);
}

.case-item[data-rar='d32ce6'] .btn-open-case {
  background: rgba(211, 44, 230, 0.4);
  border: 4px solid #ea32ff;
  box-shadow: 0px 0px 16px #d32ce6, 0px 0px 32px #d32ce6,
  inset 0px 0px 64px #d32ce6;
}

.cases .case-item[data-rar='d32ce6']:not(.disable):hover .case-cost {
  background: rgba(211, 44, 230, 0.08);
}

.case-item[data-rar='eb4b4b'] .btn-open-case {
  background: rgba(235, 75, 75, 0.4);
  border: 4px solid #ff7a7a;
  box-shadow: 0px 0px 16px #eb4b4b, 0px 0px 32px #eb4b4b,
  inset 0px 0px 64px #eb4b4b;
}

.cases .case-item[data-rar='eb4b4b']:not(.disable):hover .case-cost {
  background: rgba(235, 75, 75, 0.08);
}

.case-item[data-rar='e4ae39'] .btn-open-case {
  background: rgba(228, 174, 57, 0.4);
  border: 4px solid #f8c556;
  box-shadow: 0px 0px 16px #e4ae39, 0px 0px 32px #e4ae39,
  inset 0px 0px 64px #e4ae39;
}

.cases .case-item[data-rar='e4ae39']:not(.disable):hover .case-cost {
  background: rgba(255, 198, 0, 0.08);
}

.case-item[data-rar='06c50c'] .btn-open-case {
  background: rgba(6, 197, 12, 0.4);
  border: 4px solid #06c50c;
  box-shadow: 0px 0px 16px #06c50c, 0px 0px 32px #06c50c,
  inset 0px 0px 64px #06c50c;
}

.case-item-nums {
  position: absolute;
  top: 29px;
  right: 0;
  white-space: nowrap;
  z-index: 2;
  height: 24px;
  min-height: 24px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px 4px 4px;
  background: linear-gradient(270deg, #3c0124 -25%, #340855 125%);
  border: 2px solid rgba(63, 9, 101, 0.42);
  color: rgba(230, 155, 255, 0.4);

  @media(max-width: 1366px) {
    top: -25px;
    right: 50%;
    transform: translateX(50%);
  }

  .red {
    text-shadow: -1px -1px 0 rgba(235, 75, 75, 0.48),
    1px -1px 0 rgba(235, 75, 75, 0.48), -1px 1px 0 rgba(235, 75, 75, 0.48),
    1px 1px 0 rgba(235, 75, 75, 0.48), 0 0 24px rgba(235, 75, 75, 0.48),
    0 0 13px rgba(235, 75, 75, 0.48);
    color: #fef2c8 !important;
  }
}

.in-case-item {
  .btn-sale-cost {
    right: auto;
    z-index: 3;
    top: -7px;
    left: 30px;
    height: 24px;
    border-radius: 24px;
    padding: 1px 10px;
    opacity: 0;
    transition: all .3s;
  }

  &.active {
    .btn-sale-cost {
      top: -2px;
    }
  }

  &.empty {
    .img {
      border: 8px solid transparent;
      background: linear-gradient(180deg, rgba(139, 81, 146, 0.2), rgba(75, 51, 118, 0.2)) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;

      &:before,
      &:after {
        display: none;
      }
    }

    &:after {
      @extend %before-after;
      background: url(../images/item-empty.svg) no-repeat 0 0;
      width: 238px;
      height: 212px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -40px;
    }
  }

  &.active {
    .btn-sale-cost {
      opacity: 1;
    }
  }
}
