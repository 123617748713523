$mobile-small: 360;
$mobile: 480;
$tablet: 768;
$large: 1024;
$desktop: 1280;

@mixin mobile-small {
  @media only screen and (max-width: $mobile-small * 1px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile * 1px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet * 1px) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: $large * 1px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop * 1px) {
    @content;
  }
}
