@import '../abstracts/extends';

.page-timer {
  width: 557px;
  height: 42px;
  margin: 0 auto 44px auto;
  position: relative;

  &-wrap {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #d32ce6;
    text-shadow: 0px 2px 0px #3a004e;
    padding-left: 8px;
  }

  &:before {
    @extend %before-after;
    background: url(../images/page-timer-bg.svg) no-repeat 0 0;
    width: 100%;
    height: 111px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
  }

  &-day {
    position: relative;
    min-width: 44px;
    height: 32px;
    border-radius: 12px;
    padding: 2px 8px;
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #ffffff;
    text-shadow: 0px 2px 0px #3a004e, 0px 0px 40px #c131e4,
      0px 0px 12px rgba(193, 49, 228, 0.64);
    margin-right: 30px;

    span {
      position: relative;
    }
    &:before {
      @extend %before-after;
      width: 70px;
      height: 42px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skew(-20deg, 0deg);
      background: linear-gradient(
        180deg,
        rgba(76, 0, 85, 0) 0%,
        rgba(211, 44, 230, 0.2) 100%
      );
      margin-left: -1px;
    }
  }

  &-separator {
    opacity: 0.4;
    margin: 0 8px;
  }

  &-seconds {
    color: #ffc600;
  }

  &-close-text {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #d32ce6;
    text-shadow: 0px 2px 0px #3a004e;
    display: flex;
    align-items: center;

    .timer-title {
      margin: 0 16px;
      white-space: nowrap;
    }

    .emoji {
      font-size: 32px;
    }
  }
}
