.knife-block {
  position: fixed;
  top: 390px;
  left: 0;
  width: 200px;
  height: 100px;
  background: linear-gradient(270deg, #4a0047 0%, #0f032c 99.99%, #291033 100%);
  box-shadow: -8px 20px 16px rgba(13, 0, 28, 0.5);
  border-radius: 0px 50px 50px 0px;
  padding-top: 8px;
  z-index: 6;

  &:before {
    @extend %before-after;
    width: 100%;
    height: 100%;
    top: -8px;
    left: 0;
    background: url(../images/knife-block-mask.svg) no-repeat 0 0;
    z-index: 2;
  }

  &:after {
    @extend %before-after;
    width: 240px;
    height: 240px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(../images/knife-block-img.svg) no-repeat 0 0;
    margin: -7px 0 0 15px;
    transition: all 0.3s;
  }

  &-inside {
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #2d0159 0%, #5d0071 100%);
    border-radius: 0px 50px 50px 0px;

    &:before {
      @extend %before-after;
      width: 100%;
      height: calc(100% - 4px);
      top: 2px;
      left: 0;
      background: linear-gradient(104.04deg, #0d0329 0%, #250055 45.86%, #77007b 100%);
      border-radius: 0px 50px 50px 0px;
    }
  }

  &:hover {
    &:after {
      background: url(../images/knife-block-img-hover.svg) no-repeat 0 0;
    }
  }

  @media (max-width: 767px) {
    transform: scale(0.4);
    left: -60px;
    top: 370px;
  }
}
