.case-item .case-cost {
  //min-width: 85px;
  //padding: 0 11px 0 14px;
  min-width: 58px;
  padding: 0 8px 0 10px;
  margin-bottom: 10px;
  line-height: 32px;
  border-radius: 21px;
  position: relative;

  .btn-free-case-count {
    width: 20px;
    height: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #3B1674;
    position: absolute;
    top: -10px;
    right: 0;
  }
}

.case-item[data-rar='b0c3d9'] .case-cost {
  box-shadow: 0px 0px 3px #b0c3d9, 0px 0px 16px rgba(176, 195, 217, 0.28), 0px 0px 24px rgba(176, 195, 217, 0.28), inset 0px 0px 3px #b0c3d9, inset 0px 0px 16px rgba(176, 195, 217, 0.28), inset 0px 0px 24px rgba(176, 195, 217, 0.28);
  border: solid 2px #fff;
  background-color: rgba(254, 242, 200, 0);
  color: #b0c3d9;
}

.case-item[data-rar='5e98d9'] .case-cost {
  box-shadow: 0px 0px 3px #5e98d9, 0px 0px 16px rgba(94, 152, 217, 0.28), 0px 0px 24px rgba(94, 152, 217, 0.28), inset 0px 0px 3px #5e98d9, inset 0px 0px 16px rgba(94, 152, 217, 0.28), inset 0px 0px 24px rgba(94, 152, 217, 0.28);
  border: solid 2px #d6ddff;
  background-color: rgba(254, 242, 200, 0);
  color: #5e98d9;
}

.case-item[data-rar='4b69ff'] .case-cost {
  box-shadow: 0px 0px 3px #4b69ff, 0px 0px 16px rgba(75, 105, 255, 0.28), 0px 0px 24px rgba(75, 105, 255, 0.28), inset 0px 0px 3px #4b69ff, inset 0px 0px 16px rgba(75, 105, 255, 0.28), inset 0px 0px 24px rgba(75, 105, 255, 0.28);
  border: solid 2px #d6ddff;
  background-color: rgba(254, 242, 200, 0);
  color: #4b69ff;
}

.case-item[data-rar='e4ae39'] .case-cost {
  box-shadow: 0px 0px 3px #e4ae39, 0px 0px 16px rgba(228, 174, 57, 0.28), 0px 0px 24px rgba(228, 174, 57, 0.28), inset 0px 0px 3px #e4ae39, inset 0px 0px 16px rgba(228, 174, 57, 0.28), inset 0px 0px 24px rgba(228, 174, 57, 0.28);
  border: solid 2px #d8c2ff;
  background-color: rgba(254, 242, 200, 0);
  color: #8847ff;
}

.case-item[data-rar='8847ff'] .case-cost {
  box-shadow: 0px 0px 3px #8847ff, 0px 0px 16px rgba(136, 71, 255, 0.28), 0px 0px 24px rgba(136, 71, 255, 0.28), inset 0px 0px 3px #8847ff, inset 0px 0px 16px rgba(136, 71, 255, 0.28), inset 0px 0px 24px rgba(136, 71, 255, 0.28);
  border: solid 2px #d8c2ff;
  background-color: rgba(254, 242, 200, 0);
  color: #8847ff;
}

.case-item[data-rar='d32ce6'] .case-cost {
  border: 2px solid #D8C2FF;
  box-shadow: 0px 0px 3px #d32ce6, 0px 0px 16px rgba(211, 44, 230, 0.28), 0px 0px 24px rgba(211, 44, 230, 0.28), inset 0px 0px 3px #d32ce6, inset 0px 0px 16px rgba(211, 44, 230, 0.28), inset 0px 0px 24px rgba(211, 44, 230, 0.28);
  color: #d32ce6;
}

.case-item[data-rar='eb4b4b'] .case-cost {
  box-shadow: 0 0 16px 0 #eb4b4b, 0 0 3px 0 #eb4b4b, inset 0 0 3px 0 #eb4b4b,
  inset 0 0 24px 0 #eb4b4b;
  border: solid 2px #ffcaca;
  background-color: rgba(254, 242, 200, 0);
  color: #eb4b4b;
}

.case-item[data-rar='dea937'] .case-cost {
  box-shadow: 0px 0px 3px #dea937, 0px 0px 16px rgba(222, 169, 55, 0.28), 0px 0px 24px rgba(222, 169, 55, 0.28), inset 0px 0px 3px #dea937, inset 0px 0px 16px rgba(222, 169, 55, 0.28), inset 0px 0px 24px rgba(222, 169, 55, 0.28);
  border: solid 2px #fef0c7;
  background-color: rgba(254, 242, 200, 0);
  color: #ffc600;
}

.case-item[data-rar='e4ae39'] .case-cost {
  border: 2px solid #FFF3CE;
  box-shadow: 0px 0px 3px #FFC600, 0px 0px 16px rgba(255, 198, 0, 0.28), 0px 0px 24px rgba(255, 198, 0, 0.28), inset 0px 0px 3px #FFC600, inset 0px 0px 16px rgba(255, 198, 0, 0.28), inset 0px 0px 24px rgba(255, 198, 0, 0.28);
  color: #ffc600;
}

.case-item[data-rar='06c50c'] .case-cost {
  border: 2px solid #fff;
  box-shadow: 0px 0px 3px #06c50c, 0px 0px 16px rgba(6, 197, 12, 0.28), 0px 0px 24px rgba(6, 197, 12, 0.28), inset 0px 0px 3px #06c50c, inset 0px 0px 16px rgba(6, 197, 12, 0.28), inset 0px 0px 24px rgba(6, 197, 12, 0.28);
  color: #06c50c;
}
