@import "../abstracts/extends";

.home-category-navigation {
  position: fixed;
  right: 2.5vw;
  bottom: 180px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: end;

  &:before {
    @extend %before-after;
    background: rgba(19, 7, 44, .8);
    backdrop-filter: blur(4px);
    border-radius: 24px;
    width: 48px;
    height: 100%;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    background: rgba(176, 195, 217, 0.5);
    opacity: .99;
    box-shadow: inset 0 0 0 2px rgba(176, 195, 217, 0.5);
    border-radius: 20px;
    margin: 4px;
    padding: 0 12px;
    font-weight: 500;
    line-height: 40px;
    font-size: 0;
    cursor: pointer;

    span {
      max-height: 40px;

      &:first-letter {
        font-size: 16px;
      }
    }

    &[data-rar="e4ae39"] {
      background: rgba(228, 174, 57, 0.5);
      box-shadow: inset 0 0 0 2px rgba(228, 174, 57, 0.5);
    }

    &[data-rar="b0c3d9"] {
      background: rgba(176, 195, 217, 0.5);
      box-shadow: inset 0 0 0 2px rgba(176, 195, 217, 0.5);
    }

    &[data-rar="5e98d9"] {
      background: rgba(94, 152, 217, 0.5);
      box-shadow: inset 0 0 0 2px rgba(94, 152, 217, 0.5);
    }

    &[data-rar="4b69ff"] {
      background: rgba(75, 105, 255, 0.5);
      box-shadow: inset 0 0 0 2px rgba(75, 105, 255, 0.5);
    }

    &[data-rar="8847ff"] {
      background: rgba(136, 71, 255, 0.5);
      box-shadow: inset 0 0 0 2px rgba(136, 71, 255, 0.5);
    }

    &[data-rar="d32ce6"] {
      background: rgba(211, 44, 230, 0.5);
      box-shadow: inset 0 0 0 2px rgba(211, 44, 230, 0.5);
    }

    &[data-rar="eb4b4b"] {
      background: rgba(235, 75, 75, 0.5);
      box-shadow: inset 0 0 0 2px rgba(235, 75, 75, 0.5);
    }

    &[data-rar="ffc600"],
    &[data-rar="ffc700"] {
      background: rgba(255, 198, 0, 0.5);
      box-shadow: inset 0 0 0 2px rgba(255, 198, 0, 0.5);
    }

    &:hover {
      font-size: 16px;
    }

    &.active {
      font-size: 0;

      &[data-rar="b0c3d9"] {
        box-shadow: inset 0 0 0 4px rgba(176, 195, 217, 0.5);
      }

      &[data-rar="5e98d9"] {
        box-shadow: inset 0 0 0 4px rgba(94, 152, 217, 0.5);
      }

      &[data-rar="4b69ff"] {
        box-shadow: inset 0 0 0 4px rgba(75, 105, 255, 0.5);
      }

      &[data-rar="8847ff"] {
        box-shadow: inset 0 0 0 4px rgba(136, 71, 255, 0.5);
      }

      &[data-rar="d32ce6"] {
        box-shadow: inset 0 0 0 4px rgba(211, 44, 230, 0.5);
      }

      &[data-rar="eb4b4b"] {
        box-shadow: inset 0 0 0 4px rgba(235, 75, 75, 0.5);
      }

      &[data-rar="ffc600"],
      &[data-rar="ffc700"] {
        box-shadow: inset 0 0 0 4px rgba(255, 198, 0, 0.5);
      }
    }
  }
}